export default function useSystemPages() {
  const systemPages = computed<SystemPage[]>(() => {
    return [
      { id: 1, title: 'Vacaturebank', path: '/vacancies/search' },
      {
        id: 2,
        title: 'Organisatie registereren',
        path: '/organizations/signup',
      },
      { id: 3, title: 'Partners overzicht', path: '/gelderse-handen' },
      // TODO: typo in path. However, I'm not sure if this will break anything.
      // I need to find a way to update content links throughout the app. Or a redirect maybe?
      { id: 4, title: 'Evenementen overzicht', path: '/evenmenten' },
      { id: 5, title: '1:1 hulp overzicht', path: '/een-op-een-hulp' },
      { id: 6, title: 'Activiteiten overzicht', path: '/activiteiten' },
      { id: 7, title: 'Workshops overzicht', path: '/workshops' },
      { id: 8, title: 'Inloggen', path: '/auth/login-1' },
      { id: 9, title: 'Wachtwoord vergeten', path: '/auth/recover' },
      { id: 10, title: 'Vrijwilliger registereren', path: '/signup' },
    ]
  })

  const findSystemPageByTitle = (title: string) => {
    return systemPages.value.find(page => page.title == title)
  }

  const findSystemPageById = (id: id) => {
    return systemPages.value.find(page => page.id == id)
  }

  return {
    systemPages,
    findSystemPageByTitle,
    findSystemPageById,
  }
}
